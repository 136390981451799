
import Navbar from "../components/DashboardNavbar";
import { Outlet } from "react-router-dom";
import { LoggedInProtected } from "../components/Protected";

export default function DashboardLayout() {


    return (
        <>
            <LoggedInProtected>
                <div className="h-screen">
                    <Navbar>
                        <>    
                            <Outlet />
                        </>
                    </Navbar>


                </div>
            </LoggedInProtected>
        </>
    )
}
