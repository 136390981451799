import { UserAuth } from '../context/AuthContext';
import { Course } from '../utils/CourseClass';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'

import { Link } from 'react-router-dom';

import React, { useRef, useEffect, useState } from 'react';

import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { ReviewRequest } from '../utils/ReviewRequestClass';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import EUFooter from "../components/EUFooter";


export function ReviewRequests() {
  const { user, reviewRequests } = UserAuth();

  const [search, setSearch] = useState("");

  let filteredReq: Map<string, ReviewRequest> = new Map();


  for (const [reqId, req] of reviewRequests) {
    if ((user.authObject.uid == req.to || req.to === "none" || req.to === "default") && req.title.includes(search)) {
      filteredReq.set(reqId, req);
    }
  }

  let reqArr = Array.from(filteredReq);

  reqArr.sort(([_id1, req1], [_id2, req2]) => Number(req1.reviewed) - Number(req2.reviewed));

  return <>
    <main className="relative h-full w-full z-0 flex flex-wrap overflow-y-auto overflow-x-auto pb-8">
      <div className="flex-1 w-full ml-5 mr-5 lg:w-fit lg:min-w-fit focus:outline-none">
        <div className='mx-auto mt-20 justify-center text-center'>
          <h1 className='justify-center text-center dark:text-white'>Review Requests</h1>

          <h1 className='justify-center mt-10 text-center dark:text-white'></h1>
        </div>

        <div className="bg-white dark:bg-black py-32 px-6 lg:px-8">
          <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700 dark:text-white">

            <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
              <div className="flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
                <div className="w-full">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                      id="search"
                      name="search"
                      onChange={(e) => setSearch(e.target.value)}
                      className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      placeholder="Search"
                      type="search"
                    />
                  </div>
                </div>
              </div>
            </div>

            <ul role="list" className="flex-1 divide-y divide-gray-100">
              {reqArr.map(([id, req]) => (
                <li key={id} className="flex items-center justify-between gap-x-6 py-5">
                  <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                      <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">{req.title}</p>

                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">
                        Created at <time dateTime={req.createdAt}>{req.createdAt}</time>
                      </p>
                      {req.reviewed ?
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          Reviewed
                        </span> : <></>}


                    </div>

                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <a
                      href={"/dashboard/review/" + id}
                      className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 block"
                    >
                      View
                    </a>

                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

      </div>

      <EUFooter/> 
    </main>
  </>
}
