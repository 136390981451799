import ReactDOM from 'react-dom'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'

interface Props {
    initial: boolean
}


export default function Toggle(props: Props) {


    const [isMoon, setIsMoon] = useState(props.initial);


    function changeIcon() {
        setIsMoon(!isMoon);
    }



    return (

        <div className="text-2xl text-violet-600 bg-white dark:bg-black" >

            <FontAwesomeIcon
                icon={isMoon ? faSun : faMoon}
                onClick={changeIcon} />

        </div>

    )


}