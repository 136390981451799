import React from "react";
import { Link, Navigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  CalendarIcon,
  AcademicCapIcon,
  HomeIcon,
  LinkIcon,
  MagnifyingGlassCircleIcon,
  PencilSquareIcon,
  MapIcon,
  MegaphoneIcon,
  UserGroupIcon,
  NewspaperIcon,
  XMarkIcon,
  SpeakerWaveIcon,
} from "@heroicons/react/24/outline";

import { useLocation } from "react-router-dom";

import Notification from "./Notification";

const navigation = [
  { name: "Dashboard", href: "/dashboard", icon: HomeIcon, current: false },
  {
    name: "Courses",
    href: "/dashboard/courses",
    icon: AcademicCapIcon,
    current: false,
  },
  {
    name: "Daily Meditations",
    href: "/dashboard/audio",
    icon: SpeakerWaveIcon,
    current: false,
  },
  {
    name: "Review Requests",
    href: "/dashboard/reviews",
    icon: PencilSquareIcon,
    current: false,
  },
  {
    name: "Review Responses",
    href: "/dashboard/responses",
    icon: NewspaperIcon,
    current: false,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  children?: JSX.Element;
}

const Navbar = (props: Props) => {
  const { user, userSendEmailVerification } = UserAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const location = useLocation();

  for (const n of navigation) {
    n.current = false;
    if (n.href === location.pathname) {
      n.current = true;
    }
  }

  const handleSendMailVerification = async (e: any) => {
    e.preventDefault();
    await userSendEmailVerification();

    setShowNotification(true);
  };

  const cutDisplayName = (name: string) => {
    if (name.length < 10) {
      return name;
    }

    return name.substring(0, 7) + "...";
  };

  let validateEmail = <></>;
  if (!user.authObject.emailVerified) {
    validateEmail = (
      <div className="flex flex-shrink-0 border-t bg-orange-200 border-gray-200 p-4">
        <button
          onClick={handleSendMailVerification}
          className="text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
            />
          </svg>

          <span className="ml-2">Validate your email</span>
        </button>
      </div>
    );
  }

  let emailNotification = <></>;
  if (showNotification) {
    emailNotification = (
      <Notification
        on_close={() => setShowNotification(false)}
        title="Notification sent!"
        subtitle="Check your email."
      ></Notification>
    );
  }

  return (
    <>
      <div className="flex h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75 dark:bg-slate-800" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white dark:bg-black focus:outline-none">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1  dark:bg-black overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img
                        className="h-8 w-auto"
                        src="/seskat.png"
                        alt="Your Company"
                      />
                    </div>
                    <nav aria-label="Sidebar" className="mt-5">
                      <div className="space-y-1 px-2">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-100 dark:bg-slate-800 text-gray-900 dark:text-slate-500"
                                : "text-gray-600 dark:text-white hover:bg-gray-50 hover:text-gray-900 dark:hover:bg-slate-800",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-gray-500"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "mr-4 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </nav>
                  </div>
                  {validateEmail}
                  <div className="flex flex-shrink-0 border-t border-gray-200 dark:border-slate-800 p-4">
                    <Link
                      to="/dashboard/profile"
                      className="group block flex-shrink-0"
                    >
                      <div className="flex items-center">
                        <div>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user.authObject.photoURL}
                            alt=""
                          />
                        </div>

                        <div className="ml-3">
                          <p className="text-base font-medium text-gray-700 dark:text-slate-300 group-hover:text-gray-900 dark:group-hover:text-slate-200">
                            {cutDisplayName(user.authObject.displayName)}
                          </p>
                          <p className="text-sm font-medium text-gray-500 dark:text-slate-400 group-hover:text-gray-700 dark:group-hover:text-slate-100">
                            View profile
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="h-full hidden lg:flex lg:flex-shrink-0">
          <div className="flex w-50 flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-gray-100 dark:bg-black dark:border-slate-800">
              <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    className="h-8 m-auto w-auto"
                    src="/seskat.png"
                    alt="Your Company"
                  />
                </div>
                <nav className="mt-5 flex-1" aria-label="Sidebar">
                  <div className="space-y-1 px-2">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-200 dark:bg-slate-800 text-gray-900 dark:text-slate-500"
                            : "text-gray-600 dark:text-white hover:bg-gray-50 hover:text-gray-900 dark:hover:bg-slate-800",
                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-gray-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </nav>
              </div>
              {validateEmail}
              <div className="flex flex-shrink-0 border-t border-gray-200 dark:border-slate-800 p-4">
                <Link
                  to="/dashboard/profile"
                  className="group block w-full flex-shrink-0"
                >
                  <div className="flex items-center">
                    <div>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={user.authObject.photoURL}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-700 dark:text-slate-300 group-hover:text-gray-900 dark:group-hover:text-slate-200">
                        {cutDisplayName(user.authObject.displayName)}
                      </p>
                      <p className="text-xs font-medium text-gray-500 dark:text-slate-400 group-hover:text-gray-700 dark:group-hover:text-slate-100">
                        View profile
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex min-w-0 flex-1 flex-col">
          <div className="lg:hidden">
            <div className="flex items-center justify-between border-b border-gray-200 bg-gray-50 dark:border-slate-800 dark:bg-black px-4 py-1.5">
              <div>
                <button
                  type="button"
                  className="-mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 dark:text-white dark:hover:text-slate-400"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div>
                <img
                  className="h-8 w-auto"
                  src="/seskat.png"
                  alt="Your Company"
                />
              </div>
            </div>
          </div>
          <div className="relative  bg-gray-100 dark:bg-black  h-full z-0 flex flex-wrap overflow-y-scroll overflow-x-auto">
            {props.children}
          </div>
        </div>
      </div>

      {emailNotification}
    </>
  );
};

export default Navbar;
