import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

interface Props {
  children?: JSX.Element
}

export const LoggedInProtected = function (props: Props) {
  const { user } = UserAuth();
  if (user === null || user === undefined || Object.keys(user).length === 0) {
    return (<Navigate to='/' />);
  }

  if (props.children == null) {
    return <></>
  } else {
    return props.children;
  }
};

export const LoggedOutProtected = function (props: Props) {
  const { user } = UserAuth();
  if (user !== null && user !== undefined && Object.keys(user).length !== 0) {
    return (<Navigate to='/dashboard' />);
  }

  if (props.children == null) {
    return <></>
  } else {
    return props.children;
  }
};


