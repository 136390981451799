import { ReviewRequest } from "../utils/ReviewRequestClass";
import { useState } from 'react'
import { UserAuth } from "../context/AuthContext";
import { ReviewResponse } from "../utils/ReviewResponseClass";
import EUFooter from "../components/EUFooter";

interface Props {
  id: string,
  review: ReviewRequest
}

export default function ReviewRequestComponent(props: Props) {
  const { submitReviewResponse, reviewResponses } = UserAuth();
  let thisResponse = reviewResponses.get(props.review.responseID);

  const [feedback, setFeedback] = useState(thisResponse ? thisResponse.message : "");


  const handleSubmit = async () => {
    await ReviewRequest.markReviewed(props.id);

    let now = new Date().toDateString();
    await submitReviewResponse(new ReviewResponse(props.review.to, props.review.from, "Response for your submission for \'" + props.review.lessonID + "\' of course \'" + props.review.courseID + "\'", feedback, props.review.courseID, props.review.lessonID, props.id, props.review.resource, now));
    window.location.reload();
  }

  return <>
    <main className="relative h-full w-full z-0 flex flex-wrap overflow-y-auto overflow-x-auto pb-8">
      <div className="flex-1 w-full ml-5 mr-5 lg:w-fit lg:min-w-fit focus:outline-none">

        <div className='mx-auto mt-20 justify-center text-center'>
          <h1 className='justify-center text-center dark:text-white'>{props.review.title}</h1>

          <h1 className='justify-center mt-10 text-center dark:text-white'></h1>
        </div>
        <div className="bg-white dark:bg-black py-32 px-6 lg:px-8">
          <div className="mx-auto max-w-3xl text-base text-center leading-7 text-gray-700 dark:text-white">
            <a className="mx-auto" href={props.review.resource} target="_blank"><button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
              <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
              <span>Download</span>
            </button></a>
            <div className="mb-6">
              <label htmlFor="large-input" className="block mb-2 mt-10 text-sm font-medium text-gray-900 dark:text-white">Your review</label>
              <textarea disabled={props.review.reviewed} value={feedback} onChange={(e) => setFeedback(e.target.value)} id="large-input" rows={5} className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
              <button
                type="button"
                className="mt-5 bg-yellow-300 ml-3 hover:bg-yellow-400 dark:bg-yellow-500 dark:hover:bg-yellow-600 text-gray-800 font-bold py-2 px-4 rounded disabled:opacity-25"
                onClick={handleSubmit}
                disabled={props.review.reviewed}
              >
                Submit
              </button>

            </div>

          </div>
        </div>

      </div>
      <EUFooter/> 
    </main>

  </>
}
