import { ReviewResponse } from "../utils/ReviewResponseClass";
import EUFooter from "../components/EUFooter";

interface Props {
  resp: ReviewResponse
}

export default function ReviewResponseComponent(props: Props) {


  return <>
    <main className="relative h-full w-full z-0 flex flex-wrap overflow-y-auto overflow-x-auto pb-8">
      <div className="flex-1 w-full ml-5 mr-5 lg:w-fit lg:min-w-fit focus:outline-none">

        <div className='mx-auto mt-20 justify-center text-center'>
          <h1 className='justify-center text-center dark:text-white'>{props.resp.title}</h1>

          <h1 className='justify-center mt-10 text-center dark:text-white'></h1>
        </div>
        <div className="bg-white dark:bg-black py-32 px-6 lg:px-8">
          <div className="mx-auto max-w-3xl text-base text-center leading-7 text-gray-700 dark:text-white">

            <img className="mx-auto" src={props.resp.resource}></img>
            <div className="mb-6">
              <label htmlFor="large-input" className="block mb-2 mt-10 text-sm font-medium text-gray-900 dark:text-white">Submission review</label>
              <textarea disabled={true} value={props.resp.message} id="large-input" rows={5} className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
            </div>

          </div>
        </div>

      </div>
      <EUFooter/> 
    </main>

  </>
}
