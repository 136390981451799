import { useState } from "react";
import { Challenge } from "../../utils/LessonClass";
import { DificultySelector } from "../DificultySelector";
import e from "express";

interface Props {
    courseID: string;
    lessonID: string;
    challenge: Challenge[];
}


export function LessonQuizImg(props: Props) {

    let [dificulty, setDificulty] = useState<number | null>(null);

    let on_selected = function (d: number) {
        setDificulty(d);
    }

    let content = (
        <DificultySelector on_selected={on_selected}></DificultySelector>
    );

    if (dificulty != null) {
        content = <>
            <img src={props.challenge[dificulty].img}></img>
            <div className="mb-6">
                <label htmlFor="large-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your answer</label>
                <input type="text" id="large-input" className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
            </div>
        </>
    }

    return <>
        <div className="bg-white py-32 px-6 lg:px-8 dark:bg-black">


            <div className="mx-auto max-w-3xl text-base leading-7">
                <div className="mt-10 max-w-2xl">

                    <div className="space-y-12" >
                        {content}
                    </div>
                </div>
            </div>
        </div>
    </>
}