import { UserAuth } from "../context/AuthContext";
import { Course } from "../utils/CourseClass";
import { Link } from "react-router-dom";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { useRef, useEffect, ChangeEvent } from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import EUFooter from "../components/EUFooter";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function useHorizontalScroll() {
  const elRef: any = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e: any) => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: "smooth",
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
}

export function AudioView() {
  let { courses } = UserAuth();
  let { user } = UserAuth();
  const language: any = [];

  const [selected, setSelected] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );

  useEffect(() => {
    localStorage.setItem("selectedLanguage", selected);
    handleChange(selected);
  }, [selected]);

  const languageLabel: any = {
    en: "English",
    ro: "Romanian",
    pl: "Polish",
    it: "Italian",
    de: "German",
  };

  let filteredCourses = courses.filter((course: any) => {
    for (let i = 0; i < course.withLanguages.length; i++) {
      for (let j = 0; j < course.withLanguages[i].lessons.length; j++) {
        if (course.withLanguages[i].language === selected) {
          return course.withLanguages[i].lessons[j].lessonType === "Audio";
        }
      }
    }
  });

  const [filteredCoursesLanguage, setfilteredCoursesLanguage] =
    useState(filteredCourses);

  for (const country of filteredCourses) {
    for (const flag of country.withLanguages) {
      if (!language.includes(flag.language)) {
        language.push(flag.language);
      }
    }
  }

  const handleChange = (event: any) => {
    const filteredCourses = courses
      .map((course: any) => {
        const matchingWithLanguage = course.withLanguages.find(
          (withLanguage: any) => {
            return withLanguage.lessons.some((lesson: any) => {
              return (
                lesson.lessonType === "Audio" && withLanguage.language === event
              );
            });
          }
        );

        if (matchingWithLanguage) {
          return {
            ...course,
            withLanguages: [matchingWithLanguage],
          };
        } else {
          return null;
        }
      })
      .filter((course: any) => course !== null);
    setfilteredCoursesLanguage(filteredCourses);
    setSelected(event);
  };
  const scrollRef = useHorizontalScroll();
  let flag = (
    <>
      <Listbox value={selected} onChange={(e) => handleChange(e)}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-m font-medium leading-6 text-gray-900">
              Choose your language:
            </Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 m:text-m m:leading-6">
                <span className="flex items-center">
                  <i
                    className={`fi fi-${
                      selected === "en" ? "gb" : selected
                    } h-6 w-6 flex-shrink-0 rounded-full mr-2`}
                  ></i>
                  {languageLabel[selected]}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {flagIcon}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );

  let flagIcon = (
    <>
      {language.map((languages: any, index: any) => (
        <>
          <Listbox.Option
            key={index}
            className={({ active }) =>
              classNames(
                active ? "bg-indigo-600 text-white" : "text-gray-900",
                "relative cursor-default select-none py-2 pl-3 pr-9"
              )
            }
            value={languages}
          >
            {({ selected, active }) => (
              <>
                <div className="flex items-center ">
                  <i
                    className={`fi fi-${
                      languages === "en" ? "gb" : languages
                    } h-5 w-5 flex-shrink-0 rounded-full mr-2`}
                  ></i>
                  {languageLabel[languages]}
                </div>

                {selected ? (
                  <span
                    className={classNames(
                      active ? "text-white" : "text-indigo-600",
                      "absolute inset-y-0 right-0 flex items-center pr-4"
                    )}
                  >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                ) : null}
              </>
            )}
          </Listbox.Option>
        </>
      ))}
    </>
  );
  return (
    <>
      <main className="relative h-full w-full z-0 flex flex-wrap overflow-y-scroll overflow-x-hidden pb-8">
        <div className="mx-auto mt-20 justify-center text-center">
          <h1 className="justify-center text-center dark:text-white">
            Available Audio
          </h1>

          <h1 className="justify-center mt-10 text-center dark:text-white"></h1>
          {flag}
        </div>

        <div
          className="flex flex-row ml-2 mr-2 w-full max-h-96 border-x-4 border-gray-400 overflow-x-scroll"
          ref={scrollRef}
        >
          {filteredCoursesLanguage.map((course: Course) => (
            <div
              key={course.meta.courseID}
              className="flex-1 divide-y mr-3 ml-3 mt-2 mb-2 max-w-min divide-gray-200 rounded-lg bg-white dark:bg-slate-800 text-center shadow"
            >
              <div className="w-64 h-80 mt-5 pb-1 pt-1">
                <img
                  className="mx-auto h-32 w-32"
                  src={course.meta.previewImage}
                  alt=""
                />
                <h3 className="mt-6 text-sm font-medium text-gray-900 dark:text-slate-300">
                  <Link
                    to={
                      "/dashboard/courses/" +
                      selected +
                      "/" +
                      course.meta.courseID
                    }
                  >
                    {course.withLanguages[0].name}
                  </Link>
                </h3>
                <dl className="mt-1 flex flex-grow flex-col justify-between">
                  <dt className="sr-only">Role</dt>
                  <dd className="mt-3">
                    {course.meta.category !== "" ? (
                      <span className="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                        {course.meta.category}
                      </span>
                    ) : (
                      <></>
                    )}
                  </dd>
                </dl>
              </div>
            </div>
          ))}
        </div>
      <EUFooter/> 
      </main>
    </>
  );
}
