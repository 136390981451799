import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import ReactPlayer from 'react-player'
import { UserAuth } from "../../context/AuthContext";
import { QuestionAnswer } from "../../utils/UserClass";
import { prototype } from "events";
import { CourseTags } from "../../utils/CourseClass";

interface LessonQuiz {
  courseID: string;
  lessonID: string;
  resource: DetailedQuizInterface[];
  userAnswers: QuestionAnswer[];
}



function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}
interface DetailedQuizInterface {
  question: string;
  answers: string[];
  multipleAnswer: boolean;
  correctAnswer: string | string[];
  points: number;
  explanation: string;
  tagsPoints: number[];
}

function LessonQuiz(props: LessonQuiz) {


  let [handler, setHandler] = useState<Boolean>(true);

  let questionsInactive = props.userAnswers.length === props.resource.length;

  const [answers, setAnswers] = useState<QuestionAnswer[]>(questionsInactive ? props.userAnswers : Array.from(Array(props.resource.length), () => { return { answers: [] } }));

  const { user, userLessonsCourse } = UserAuth();

  const [clicked, setClicked] = useState(0);

  const handleChange = (event: any) => {

    const { value } = event.currentTarget;


    if (!props.resource[clicked].multipleAnswer) {
      answers[clicked].answers = [value];
      setAnswers([...answers]);
    }
    else {

      let found = answers[clicked].answers.indexOf(value);

      if (found == -1) {
        answers[clicked].answers.push(value);
      } else {
        answers[clicked].answers.splice(found, 1);
      }
      setAnswers([...answers]);
    }


  };

  useEffect(() => {

  })


  function next() {
    setClicked(clicked + 1)
  }

  function prev() {
    setClicked(clicked - 1)
  }



  function handleSubmit(e: any) {


    e.preventDefault();

    if (questionsInactive) {
      setHandler(false);
      return;
    }

    user.finishedUserLessons(props.courseID, { lessonID: props.lessonID, answerID: answers });
    setHandler(false);
  }

  let explanation = <></>;

  if (questionsInactive) {
    let answerVerdict = <span className="text-green-500">Correct</span>
    // we only care for 1 answer
    if (props.resource[clicked].correctAnswer !== props.userAnswers[clicked].answers[0]) {
      answerVerdict = <span className="text-red-500">Wrong</span>
    }

    explanation = <p>
      {answerVerdict}: {props.resource[clicked].explanation}
    </p>
  }


  let content = <>
    <form onSubmit={handleSubmit}>

      <>

        <div className="space-y-12" >
          <div className="border-b border-gray-900/10 pb-12 dark:border-slate-500">

            <p className="mt-1 text-2xl leading-6 text-gray-600 dark:text-white">
              {props.resource[clicked].question}
            </p>

            {props.resource[clicked].multipleAnswer === false ? (
              <p className="mt-5 text-sm leading-6 text-gray-600 dark:text-slate-200">
                Choose one answer.
              </p>
            )
              :
              (<p className="mt-5 text-sm leading-6 text-gray-600 dark:text-slate-200">
                Choose one or multiple answers.
              </p>
              )

            }



          </div>
          <div className="border-b border-gray-900/10 pb-12">


            <div className="mt-10 space-y-10">

              <fieldset>

                <div className="mt-6 space-y-6">


                  {


                    props.resource[clicked].answers.map((item, index) => (

                      <div className="relative flex gap-x-3" key={item} >
                        <div className="flex h-6 items-center">


                          <div>

                            {
                              props.resource[clicked].multipleAnswer === false ?
                                (

                                  <input
                                    id={index.toString()}
                                    name={item}
                                    value={item}
                                    disabled={questionsInactive}
                                    type="radio"
                                    className="h-4 w-4 border-gray-300  focus:ring-indigo-600"

                                    checked={answers[clicked].answers.some(val => val.toString() === item)}
                                    onChange={handleChange}
                                  />

                                ) :
                                (

                                  <input
                                    id={index.toString()}
                                    value={item}
                                    name={item}
                                    disabled={questionsInactive}
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300  focus:ring-indigo-600"

                                    checked={answers[clicked].answers.some(val => val === item)}
                                    onChange={handleChange}
                                  />
                                )
                            }
                          </div>

                        </div>
                        <div className="text-sm leading-6">
                          <label htmlFor={index.toString()} className="font-medium text-gray-900 dark:text-slate-300">
                            {item}
                          </label>
                        </div>
                      </div>


                    ))
                  }

                  {explanation}




                </div>
              </fieldset>

            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">


        </div>


      </>

      <div className="md:text-right">

        {



          clicked + 1 === props.resource.length ? (

            handler === true ? (<button
              type="submit"
              onClick={(event) => handleSubmit(event)}
              className="bg-green-300 hover:bg-green-400 dark:bg-green-500 dark:hover:bg-green-600 text-gray-800 font-bold py-2 px-4 rounded"
            >
              {
                questionsInactive ? "Check answers" : "Submit answers"
              }
            </button>
            ) :
              (
                <>
                </>
              )


          ) : (


            <></>
          )
        }
      </div>





    </form>

    <div className="col">
      <a className={classNames('text-gray-300', 'group  items-center px-2 py-2 text-sm font-medium rounded-md')}>
        {`Questions: ` + (clicked + 1) + `/` + props.resource.length}
      </a>

      {

        clicked + 1 !== props.resource.length ? (


          clicked !== 0 ? (
            <>                        <button className={classNames(' bg-gray-600 text-white mr-3', 'group  items-center px-2 py-2 text-sm font-medium rounded-md')}

              onClick={prev}

            >
              Prev
            </button>

              <button className={classNames(' bg-gray-600 text-white', 'group mr-2 items-center px-2 py-2 text-sm font-medium rounded-md')}

                onClick={next}

              >
                Next
              </button>
            </>



          ) : (
            <>
              <button className={classNames(' bg-gray-600 text-white', 'group mr-2 items-center px-2 py-2 text-sm font-medium rounded-md')}

                onClick={next}

              >
                Next
              </button>
            </>)






        ) : (

          <button className={classNames(' bg-gray-600 text-white', 'group  items-center px-2 py-2 text-sm font-medium rounded-md')}

            onClick={prev}

          >
            Prev
          </button>
        )

      }
    </div>
  </>


  if (!handler) {
    let points = new Array(CourseTags.length).fill(0);
    let max_points = new Array(CourseTags.length).fill(0);

    for (let i = 0; i < props.resource.length; i++) {
      for (let j = 0; j < CourseTags.length; j++) {
        max_points[j] += props.resource[i].tagsPoints[j];
        if (answers[i].answers[0] === props.resource[i].correctAnswer) {
          points[j] += props.resource[i].tagsPoints[j];
        }
      }

    }

    const point = points.map((v, idx) => [v, idx]).filter(([v, idx]) => v != 0).map(([v, idx]) =>
      <p key={idx} className="mt-5 text-sm leading-6 text-gray-600 dark:text-slate-200">{CourseTags[idx]}: {v}/{max_points[idx]}</p>
    );


    if (point.length === 0) {
      point.push(<p key={0} className="mt-5 text-sm leading-6 text-gray-600 dark:text-slate-200">None</p>)
    }

    content = <h1 className="mt-2 text-3xl text-center font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">Your score: {
      (point)
    }</h1 >
  }

  return (

    <div className="bg-white py-32 px-6 lg:px-8 dark:bg-black">


      <div className="mx-auto max-w-3xl text-base leading-7">
        <div className="mt-10 max-w-2xl">

          {content}

        </div>
      </div>


    </div>


  )
}

export default LessonQuiz;
