import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import ReactPlayer from 'react-player'


interface LessonVideo {
  description: string;
  lessontitle: string;
  video: string;

}



function LessonVideo(props: LessonVideo) {



  return (

    <div className="bg-white dark:bg-black  py-32 px-6 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700 dark:text-white">
        <h1 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-white">{props.lessontitle}</h1>
        <div className="mx-auto mt-10 max-w-2xl h-80 min-w-80">
          <div className="mx-auto mt-10 text-center mb-10">{props.description}</div>
          <ReactPlayer width="100%" height="100%" url={props.video}
            controls />


        </div>
      </div>
    </div>

  )
}

export default LessonVideo;
