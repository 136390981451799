import React, { useEffect, useState, ChangeEvent } from "react";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { UserAuth } from '../../context/AuthContext'
import { ReviewRequest } from "../../utils/ReviewRequestClass";
import { Course } from "../../utils/CourseClass";


interface LessonText {
  description: string;
  lessontitle: string;
  lessonID: string;
  course: Course;

  complitionMessage: string;
  hasUpload: boolean;
}

function LessonText(props: LessonText) {

  const { user, uploadFile, getFileUrl, submitReviewRequest } = UserAuth();
  const uploadCloudUrl = "/upload-cloud.jpg";
  const exerciseImageUrl = "images/exercises/" + user.authObject.uid + "/" + user.currentTeacher + "/" + props.lessonID;

  let [isSubmited, setIsSubmited] = useState(false);

  useEffect(() => {
    if (!isSubmited) {
      getFileUrl(exerciseImageUrl).then((u: any) => { setIsSubmited(true); setUploadedUrl(u) }).catch(() => { });
    }
  }, []);

  let [uploadedUrl, setUploadedUrl] = useState(uploadCloudUrl);
  let [file, setFile] = useState<File>();

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      let url = URL.createObjectURL(file);
      setUploadedUrl(url);
    }
  };

  const onSubmit = async () => {
    if (!file)
      return;

    const url = await uploadFile(file, exerciseImageUrl);
    setIsSubmited(true)
    if (user.currentTeacher != "default") {
      let now = new Date().toDateString();
      submitReviewRequest(new ReviewRequest(user.authObject.uid, user.currentTeacher, user.authObject.displayName + " submission for \'" + props.lessontitle + "\' of course \'" + props.course.withLanguages[0].name + "\'", "This is " + user.authObject.displayName + " submission for course " + props.course.withLanguages[0].name + " lesson " + props.lessontitle, props.course.withLanguages[0].name, props.lessontitle, url, false, now, ""))
    }
  }

  const onRemove = () => {
    setUploadedUrl(uploadCloudUrl);
  }

  let complitionMessage = <></>;

  if (isSubmited) {
    complitionMessage = <p>
      {props.complitionMessage}
    </p>
  }


  let upload = <></>;
  if (props.hasUpload) {
    let buttons = <></>;
    if (!isSubmited) {
      buttons = <div>
        <div className="col-span-1">

          <input onChange={handleFileChange} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input" type="file" />

        </div>
        <div className="col-span-1">
          <button
            onClick={onRemove}
            type="button"
            className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-red-200 uppercase last:mr-0 mr-1"
          >
            Remove
          </button>
        </div>
        <div className="col-span-1">
        </div>


        <button
          onClick={onSubmit}
          type="button"
          className="mt-5 bg-yellow-300 ml-3 hover:bg-yellow-400 dark:bg-yellow-500 dark:hover:bg-yellow-600 text-gray-800 font-bold py-2 px-4 rounded disabled:opacity-25"
        >
          Submit
        </button>
      </div>;
    } else {
      buttons = <div>
        <a className="mx-auto" href={uploadedUrl} target="_blank">
          <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
            <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
            <span>Download</span>
          </button>
        </a>
      </div>
    }


    upload = <div className="mt-6 border-t border-gray-100">
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <div>
          <h1 className="text-lg font-bold text-center justify-center">Upload result</h1>
        </div>
        <div className="mt-1 flex items-center">
          {buttons}
        </div>
      </div>

      {complitionMessage}

    </div>
  }


  return (

    <div className="bg-white dark:bg-black py-32 px-6 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700 dark:text-white">
        <p className="text-base font-semibold leading-7 text-indigo-600 dark:text-white"> </p>
        <h1 className="mt-2 text-3xl font-bold  text-center tracking-tight text-gray-900 dark:text-white sm:text-4xl">{props.lessontitle}</h1>
        <div className="mt-10">
          <div>
            <article className="prose lg:prose-xl dark:text-white">
              <ReactMarkdown children={props.description} components={{
                h1(props) {
                  const { node, ...rest } = props
                  return <h1 className="dark:text-white" {...rest} />
                },
                h2(props) {
                  const { node, ...rest } = props
                  return <h2 className="dark:text-white" {...rest} />
                },
                h3(props) {
                  const { node, ...rest } = props
                  return <h3 className="dark:text-white" {...rest} />
                },
                h4(props) {
                  const { node, ...rest } = props
                  return <h4 className="dark:text-white" {...rest} />
                }
              }}
                remarkPlugins={[remarkGfm]} />

            </article>
          </div>

          {upload}

        </div>
      </div>
    </div>

  )
}

export default LessonText;
