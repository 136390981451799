export default function Loading() {
    return (
        <div className="flex h-screen bg-slate-700">
            <div className="m-auto">
                <div role="status">

                    <img className='inline mr-2 animate-bounce w-20 h-20' alt="e" src="/seskat.png"></img>
                </div>
            </div>
        </div>
    )
}