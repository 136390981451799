import { Link } from "react-router-dom";
export default function Navbar() {
  return (
    <header>
      <div className="mx-auto flex max-w-7xl items-center justify-between p-6 md:justify-start md:space-x-10 lg:px-8">
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <Link to="/">
            <span className="sr-only">Your Company</span>
            <img className="h-8 w-auto sm:h-10" src="/seskat.png" alt="" />
          </Link>
        </div>

        <div className="items-center justify-end md:flex md:flex-1 lg:w-0">
          <Link
            to="/"
            className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Sign in
          </Link>
          <Link
            to="/signup"
            className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-purple-700 hover:to-indigo-700"
          >
            Sign up
          </Link>
        </div>
      </div>
    </header>
  );
}
