import React from "react";
import { Route, Routes } from "react-router-dom";

import DashboardLayout from "./pages/DashboardLayout";
import LandingLayout from "./pages/LandingLayout";
import Home from "./pages/Home";
import { AuthContextProvider } from "./context/AuthContext";
import Account from "./pages/Account";
import Signin from "./pages/SignIn";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import CourseView from "./pages/CourseView";
import { Landing } from "./pages/Landing";
import { CoursesView } from "./pages/CoursesView";
import { MyCourses } from "./pages/MyCourses";
import { AudioView } from "./pages/AudioView";
import MyCourseView from "./pages/MyCourseView";
import { ReviewRequests } from "./pages/ReviewRequests";
import ReviewRequestView from "./pages/ReviewRequest";
import ReviewResponseView from "./pages/ReviewResponse";
import { ReviewResponses } from "./pages/ReviewResponses";
import EUFooter from "./components/EUFooter";

function App() {
  return (
    <>
    <AuthContextProvider>
      <Routes>
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route path="/dashboard" element={<Home />} />
          <Route path="/dashboard/my-courses" element={<MyCourses />} />
          <Route path="/dashboard/courses" element={<CoursesView />} />
          <Route path="/dashboard/audio" element={<AudioView />} />

          <Route path="/dashboard/profile" element={<Profile />} />
          <Route path="/dashboard/courses/:lang/:id" element={<CourseView />} />
          <Route
            path="/dashboard/my-courses/:lang/:id"
            element={<MyCourseView />}
          />
          <Route path="/dashboard/review/:id" element={<ReviewRequestView />} />
          <Route path="/dashboard/reviews" element={<ReviewRequests />} />
          <Route
            path="/dashboard/response/:id"
            element={<ReviewResponseView />}
          />
          <Route path="/dashboard/responses" element={<ReviewResponses />} />
          <Route path="/dashboard/account" element={<Account />} />
        </Route>

        <Route path="/" element={<Signin />}>
          {" "}
        </Route>
        <Route index path="/signup" element={<Register />} />
      </Routes>
    </AuthContextProvider>
  
    </>
  );
}

export default App;
