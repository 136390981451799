import { UserAuth } from "../context/AuthContext";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

export default function Suggestions() {
  let { courses } = UserAuth();

  if (courses !== null) {
    let suggestionsList = [];
    for (let i = 0; i < courses.length && i < 7; i++) {
      suggestionsList.push(
        <li key={i} className="relative w-full flex justify-between py-5">
          <div className="flex gap-x-4 w-full pr-6 sm:w-1/2 sm:flex-none">
            <img
              className="h-12 w-12 flex-none rounded-full bg-gray-50"
              src={courses[i].meta.previewImage}
              alt=""
            />
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-900">
                <a
                  href={
                    "/dashboard/courses/" +
                    "en" +
                    "/" +
                    courses[i].meta.courseID
                  }
                >
                  <span className="absolute inset-x-0 -top-px bottom-0" />
                  {courses[i].meta.category}
                </a>
              </p>
            </div>
          </div>
          <div className="flex justify-end gap-x-4 sm:w-1/2 sm:flex-none">
            <div className="flex justify-end gap-x-4 sm:w-1/2 sm:flex-none">
              <ChevronRightIcon
                className="h-5 w-5 lg:ml-48 sm:ml-10 flex-none text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </li>
      );
    }

    return (
      <ul
        role="list"
        className=" overflow-y-auto h-auto divide-y divide-gray-100"
      >
        {suggestionsList}
      </ul>
    );
  } else {
    return (
      <p className="text-sm text-gray-500 dark:text-slate-200">
        You enrolled in all our courses
      </p>
    );
  }
}
