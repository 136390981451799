import { Course } from "../utils/CourseClass";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import { UserAuth } from "../context/AuthContext";
import { User } from "../utils/UserClass";
import { Link, useNavigate } from "react-router-dom";
import EUFooter from "../components/EUFooter";

import Notification from "./Notification";

interface Props {
  course: Course;
  lang: any;
}
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function EnrollCourseComponent(props: Props) {
  const course = props.course;
  const lang = props.lang;
  const [isEnrolled, setIsEnrolled] = useState(false);
  const navigate = useNavigate();
  const [_, setShowNotification] = useState(false);

  const { user } = UserAuth();
  const { userCourses, setUserCourses } = UserAuth();

  useEffect(() => {
    for (let i = 0; i < userCourses.length; i++) {
      if (userCourses[i].meta.courseID === props.course.meta.courseID) {
        setIsEnrolled(true);
      }
    }
  }, []);

  function enrollCourse() {
    if (isEnrolled === true) {
    } else {
      if (course.withLanguages[0].lessons[0].lessonType !== "Audio") {
        setShowNotification(true);
        user.enrollUserCourse(course.meta.courseID);

        userCourses.push(course);
        setUserCourses(userCourses);
      }
    }
    navigate(`/dashboard/my-courses/` + lang + `/` + course.meta.courseID);
  }

  return (
    <>
      <div className="relative z-0 flex-1 h-full focus:outline-none">
        <div className="inset-0 py-6 px-4 sm:px-6 lg:px-8 h-full overflow-y-scroll">
          <div className="relative bg-white dark:bg-slate-800">
            <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
              <div className="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
                <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
                  <img
                    className="absolute inset-0 h-full w-full bg-gray-50 object-cover"
                    src={course.meta.previewImage}
                    alt=""
                  />
                </div>
              </div>
              <div className="px-6 lg:contents">
                <div className="mx-auto max-w-2xl pt-16 pb-24 sm:pt-20 sm:pb-32 lg:mr-0 lg:ml-8 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
                  <p className="text-base font-semibold leading-7 text-indigo-600 dark:text-indigo-300">
                    {course.meta.category}
                  </p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-white">
                    {course.withLanguages[0].name}
                  </h1>
                  <p className="mt-6 text-xl leading-8 text-gray-700 dark:text-slate-300">
                    {course.withLanguages[0].shortDescription}
                  </p>

                  <div className="mt-10 flex">
                    <button
                      onClick={() => enrollCourse()}
                      className="rounded-md bg-indigo-600 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      {isEnrolled === true
                        ? "Continue your course !"
                        : "Enroll to course !"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      <EUFooter/> 
        </div>
      </div>
    </>
  );
}
