
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { UserAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import Notification from '../components/Notification';
import Chart, { Filler, LineElement, PointElement, RadialLinearScale } from 'chart.js/auto';
import Toggle from "../components/Toggle";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import EUFooter from "../components/EUFooter";


import {
    ArrowRightOnRectangleIcon
} from '@heroicons/react/24/outline'


function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}




export default function Profile() {


    const { user, logOut, uploadFile, allUsers, updateUser, userSendPasswordReset, userLessonsCourse, userCourses } = UserAuth();

    let [error, setError] = useState("");
    let [username, setUsername] = useState(user.authObject.displayName);
    let [profileSrc, setProfileSrc] = useState(user.authObject.photoURL);
    let [showNotification, setShowNotification] = useState(false);
    let [darkMode, setDarkMode] = useState(user.preferedTheme == "dark");
    let [file, setFile] = useState<File>();
    let [currentTeacher, setCurrentTeacher] = useState<string>(user.currentTeacher);
    let [role, setRole] = useState<string>(user.role);

    let allRoles = ["Teacher", "Subscriber"];

    const onSave = async (e: any) => {
        e.preventDefault()
        let url = undefined;

        if (username.length > 12) {
            setError("Username too long");
            return;
        }

        if (file) {
            // we dont accept files bigger than 500k
            if (file.size > 500 * 1024) {
                setError("File too big");
                return;
            }

            url = await uploadFile(file, "images/profile/" + user.authObject.uid);
        }

        if (darkMode) {
            user.preferedTheme = "dark"
        } else {
            user.preferedTheme = "light"
        }

        user.currentTeacher = currentTeacher;
        user.role = role;

        await updateUser(username, url);
        await user.saveDBUser();
        window.location.reload();
    }

    const onThemeChange = () => {

        setDarkMode(!darkMode);
    }

    const onRemove = () => {
        setProfileSrc(user.authObject.photoURL);
    }

    const onResetPassword = (e: any) => {
        e.preventDefault();
        userSendPasswordReset();
        setShowNotification(true);
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            let url = URL.createObjectURL(e.target.files[0]);
            setProfileSrc(url);
        }
    };

    const handleSignOut = async () => {
        try {
            await logOut()
        } catch (error) {
            console.log(error)
        }
    }

    let resetNotification = <></>
    if (showNotification) {
        resetNotification = <Notification on_close={() => setShowNotification(false)} title="Password reset email sent!" subtitle="Check your email."></Notification>
    }


    let allTeachers = allUsers.filter((u: [string, string]) => u[1] !== undefined && u[1] !== "Subscriber");
    let teachersCodes = (
        <>
            {allTeachers.map(([id, role]: [string, string]) => (
                <Listbox.Option key={id}
                    className={({ active }) =>
                        classNames(
                            active ? "bg-indigo-600 text-white" : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                    }
                    value={id}>
                    {({ selected, active }) => (
                        <>
                            <div className="flex items-center">
                                <i>{id}</i>
                            </div>

                            {selected ? (
                                <span
                                    className={classNames(
                                        active ? "text-white" : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                            ) : null}
                        </>
                    )}

                </Listbox.Option>
            ))}
        </>
    );


    let rolesCodes = (
        <>
            {allRoles.map((role: string) => (
                <Listbox.Option key={role}
                    className={({ active }) =>
                        classNames(
                            active ? "bg-indigo-600 text-white" : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                    }
                    value={role}>
                    {({ selected, active }) => (
                        <>
                            <div className="flex items-center">
                                <i>{role}</i>
                            </div>

                            {selected ? (
                                <span
                                    className={classNames(
                                        active ? "text-white" : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                            ) : null}
                        </>
                    )}

                </Listbox.Option>
            ))}
        </>
    );


    return (

        <>


            <div className="mt-8 m-auto w-full">


                <div className="bg-white dark:bg-black py-8 px-4 sm:px-10">


                    <div>
                        <div className="px-4 sm:px-0">
                            <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Profile</h3>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-slate-300">This information will be displayed publicly so be careful what you share.</p>
                        </div>
                        <div className="mt-6 border-t border-gray-100">
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">Username</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"><input
                                        onChange={(e) => setUsername(e.target.value)}
                                        type="text"
                                        name="first-name"
                                        id="first-name"
                                        value={username}
                                        autoComplete="given-name"
                                        className="mt-1 block rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    /></dd>
                                </div>


                            </dl>
                        </div>
                        <div className="mt-6 border-t border-gray-100">
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">Profile picture</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"> <div className="mt-1 flex items-center">
                                        <img
                                            className="inline-block h-12 w-12 rounded-full"
                                            src={profileSrc}
                                            alt=""
                                        />
                                        <div className="ml-4 flex">
                                            <div className="relative flex cursor-pointer items-center rounded-md border bg-white dark:bg-slate-400 dark:border-slate-300 py-2 px-3 shadow-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 hover:bg-blue-gray-50">
                                                <label
                                                    htmlFor="user-photo"
                                                    className="pointer-events-none relative text-sm font-medium text-blue-gray-900"
                                                >
                                                    <span>Change</span>
                                                    <span className="sr-only"> user photo</span>
                                                </label>
                                                <input
                                                    onChange={handleFileChange}
                                                    accept="image/*"
                                                    id="user-photo"
                                                    name="user-photo"
                                                    type="file"
                                                    className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                                                />
                                            </div>
                                            <button
                                                onClick={onRemove}
                                                type="button"
                                                className="ml-3 rounded-md border border-transparent bg-transparent py-2 px-3 text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 dark:text-white dark:hover:text-slate-300 focus:border-blue-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-gray-50"
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div></dd>
                                </div>


                            </dl>
                        </div>
                        <div className="mt-6 border-t border-gray-100">
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">Teacher code</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <Listbox value={currentTeacher} onChange={(e) => setCurrentTeacher(e)}>
                                            {({ open }) => (
                                                <>
                                                    <div className="relative mt-2">
                                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 m:text-m m:leading-6">
                                                            {currentTeacher}
                                                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                                                <ChevronUpDownIcon
                                                                    className="h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                        </Listbox.Button>

                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {teachersCodes}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </>
                                            )}
                                        </Listbox>

                                    </dd>
                                </div>


                            </dl>
                        </div>
                        <div className="mt-6 border-t border-gray-100">
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">Your Role</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <Listbox value={role} onChange={(e) => setRole(e)}>
                                            {({ open }) => (
                                                <>
                                                    <div className="relative mt-2">
                                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 m:text-m m:leading-6">
                                                            {role}
                                                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                                                <ChevronUpDownIcon
                                                                    className="h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                        </Listbox.Button>

                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {rolesCodes}
                                                            </Listbox.Options>

                                                        </Transition>
                                                    </div>
                                                </>
                                            )}
                                        </Listbox>

                                        {role === "Teacher" ? <p className='mt-2 text-center'>Your teacher code: <span className='font-bold'>{user.authObject.uid}</span></p> : <></>}
                                    </dd>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    </dd>
                                </div>


                            </dl>
                        </div>

                        <div className="mt-6 border-t border-gray-100">
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">Theme</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"><div className="sm:col-span-6 inline-flex">
                                        <span onClick={onThemeChange}><Toggle initial={!darkMode} /></span>
                                    </div> </dd>
                                </div>


                            </dl>
                        </div>

                    </div>

                    <form className="divide-y-blue-gray-200 dark:divide-slate-800 mt-6 space-y-8 divide-y">
                        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">

                            <div className="sm:col-span-6">
                                <button
                                    onClick={onResetPassword}
                                    className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-2 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                >
                                    Change Password
                                </button>
                            </div>

                            <div className="sm:col-span-6">
                                <button
                                    onClick={handleSignOut}
                                    className="inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                >
                                    <ArrowRightOnRectangleIcon className='w-6 mr-2' />Sign Out
                                </button>
                            </div>


                            <p className='text-red-600'>{error}</p>
                        </div>

                        <div className="flex justify-end pt-8">
                            <Link to="/dashboard">
                                <button
                                    type="button"
                                    className="rounded-md border border-gray-300 bg-white dark:bg-slate-400 dark:border-slate-800 py-2 px-4 text-sm font-medium text-blue-gray-900 shadow-sm hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                >
                                    Cancel
                                </button>
                            </Link>
                            <button
                                onClick={onSave}
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                    <div className='mx-auto max-w-2xl'>

                    </div>
                </div >

      <EUFooter/> 
            </div >


        </>
    )
}
