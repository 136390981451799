export default function EUFooter() {
 return (
   <div className="mt-2 grid grid-cols-4 ">
      <div></div>
      <img className="h-32" src="/eu.png"/>
      <p className="text-xs">Funded by the European Union. Views and opinions expressed are however those of the author(s)
only and do not necessarily reflect those of the European Union or the European Education and
Culture Executive Agency (EACEA). Neither the European Union nor EACEA can be held
responsible for them.</p>
      <div></div>
    </div>
 )
}
