import { Course } from "../utils/CourseClass";
import { useEffect, useState } from "react";
import LessonText from "./LessonsComponents/LessonText";
import LessonVideo from "./LessonsComponents/LessonVideo";
import LessonPDF from "./LessonsComponents/LessonPDF";
import LessonAudio from "./LessonsComponents/LessonAudio";
import LessonImage from "./LessonsComponents/LessonImage";
import { UserAuth } from "../context/AuthContext";
import LessonQuiz from "./LessonsComponents/LessonQuiz";
import { User } from "../utils/UserClass";
import EUFooter from "../components/EUFooter";

import ReactMarkdown from "react-markdown";

import { LessonQuizImg } from "./LessonsComponents/LessonQuizImg";
import { QuizImgResource, QuizResource } from "../utils/LessonClass";

import Notification from "./Notification";

import { CheckIcon } from "@heroicons/react/20/solid";

interface Props {
  course: Course;
}
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function CourseComponent(props: Props) {
  const { userCourses, userLessonsCourse, setUserLessonsCourse } = UserAuth();
  const lessons = props.course.withLanguages[0].lessons;
  const [clicked, setClicked] = useState(-1);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationClosed, setNotificationClosed] = useState(false);

  const on_notification_closed = function() {
    setShowNotification(false);
    setNotificationClosed(true);
  };

  if (
    clicked === lessons.length - 1 &&
    !showNotification &&
    !notificationClosed
  ) {
    setShowNotification(true);
  }

  let finish_notification = showNotification ? (
    <Notification
      on_close={on_notification_closed}
      title={"You finished a course!"}
      subtitle={
        "The " +
        props.course.withLanguages[0].name +
        " course was added to your profile. You can retake it anytime."
      }
    />
  ) : (
    <></>
  );

  let { user } = UserAuth();
  const [lessonsCount, setLessonCount] = useState(0);
  //const [userLessonsClicked, setUserLessonsClicked] =useState<Array<string>>([]);
  let userLessonsClicked = [];

  function next() {
    if (clicked > -1) {
      //console.log('f', props.course.withLanguages[0].lessons[clicked].lessonID)
      //user.completelUserLessons( props.course.withLanguages[0].lessons[clicked].lessonID);
    }
  }


  let thisLessonAnswers = [];
  if (clicked != -1) {

    let courses = user.courses.get(user.currentTeacher);
    for (let i = 0; i < courses.length; i++) {
      if (courses[i].courseID === props.course.meta.courseID) {
        for (let lesson of courses[i].lessonsFinished) {
          if (lesson.lessonID === lessons[clicked].lessonID) {
            thisLessonAnswers = lesson.answerID;
          }
        }

        break;
      }
    }
  }

  if (clicked > -1) {
    if (lessons[0].lessonType !== "Audio") {
      for (let i = 0; i < userLessonsCourse.length; i++) {
        if (userLessonsCourse[i].courseID === props.course.meta.courseID) {
          if (userLessonsCourse[i].lessonsFinished.length === 0) {
            user.finishedUserLessons(props.course.meta.courseID, {
              lessonID: props.course.withLanguages[0].lessons[clicked].lessonID,
              answerID: [],
            });
          }
        }
      }
    }

    if (lessons[clicked].lessonType !== "Quiz") {
      if (lessons[0].lessonType !== "Audio") {
        user.finishedUserLessons(props.course.meta.courseID, {
          lessonID: lessons[clicked].lessonID,
          answerID: [],
        });

        setUserLessonsCourse(userLessonsCourse);
      }
    }
  }

  return (
    <>
      {clicked !== -1 ? (
        <div className="relative z-0 flex-1 w-full min-w-full h-full lg:w-fit lg:min-w-fit focus:outline-none">
          {/* Start main area*/}
          <div className="inset-0 py-6 px-4 sm:px-6 lg:px-8 h-full w-full overflow-y-scroll dark:bg-slate-800 dark:text-white">
            <div
              role="status"
              className="justify-center  m-auto mt-10 animate-pulse "
            >
              <span className="sr-only">Loading...</span>
            </div>

            {lessons[clicked].lessonType === "Text" ? (
              <LessonText
                key={clicked}
                description={lessons[clicked].description}
                course={props.course}
                lessontitle={lessons[clicked].title}
                hasUpload={lessons[clicked].resource.source !== "false"}
                lessonID={lessons[clicked].lessonID}
                complitionMessage={
                  (lessons[clicked].resource as any).submitMessage !== undefined
                    ? (lessons[clicked].resource as any).submitMessage
                    : ""
                }
              />
            ) : lessons[clicked].lessonType === "Video" ? (
              <LessonVideo
                description={lessons[clicked].description}
                lessontitle={lessons[clicked].title}
                video={lessons[clicked].resource.source}
              />
            ) : lessons[clicked].lessonType === "PDF" ? (
              <LessonPDF
                description={lessons[clicked].description}
                lessontitle={lessons[clicked].title}
                file={lessons[clicked].resource.source}
                filename={lessons[clicked].resource.title}
              />
            ) : lessons[clicked].lessonType === "Audio" ? (
              <LessonAudio
                description={lessons[clicked].description}
                lessontitle={lessons[clicked].title}
                audio={lessons[clicked].resource.source}
              />
            ) : lessons[clicked].lessonType === "Image" ? (
              <LessonImage
                description={lessons[clicked].description}
                lessontitle={lessons[clicked].title}
                img={lessons[clicked].resource.source}
              />
            ) : lessons[clicked].lessonType === "Quiz" ? (
              <LessonQuiz
                key={clicked}
                courseID={props.course.meta.courseID}
                lessonID={lessons[clicked].lessonID}
                resource={(lessons[clicked].resource as QuizResource).quiz}
                userAnswers={thisLessonAnswers}
              />
            ) : lessons[clicked].lessonType === "QuizImg" ? (
              <LessonQuizImg
                courseID={props.course.meta.courseID}
                lessonID={lessons[clicked].lessonID}
                challenge={
                  (lessons[clicked].resource as QuizImgResource).challenge
                }
              />
            ) : (
              "Nothing"
            )}

            <div className="grid grid-cols-2 mt-6">
              <div className="justify-left ml-2">
                <button
                  onClick={() => setClicked(clicked - 1)}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                >
                  prev
                </button>
              </div>
              <div className="justify-right text-right mr-2">
                {lessons.length - 1 <= clicked ? (
                  <div></div>
                ) : (
                  <button
                    className="bg-green-300 hover:bg-green-400 dark:bg-green-500 dark:hover:bg-green-600 text-gray-800 font-bold py-2 px-4 rounded"
                    onClick={() => setClicked(clicked + 1)}
                  >
                    {lessons[clicked].lessonType === "QuizImg"
                      ? "submit"
                      : "next"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-2 relative z-0 flex-1 w-full min-w-full h-full lg:w-fit lg:min-w-fit focus:outline-none">
          <div className="m-auto mt-10 flex items-center justify-center w-full h-48 rounded sm:w-96 dark:bg-gray-700">
            <img
              className="object-scale-down h-64 w-96"
              src={props.course.meta.previewImage}
            ></img>
          </div>
          {/* <div className="m-auto mt-20 flex items-center text-center justify-center w-full dark:bg-gray-700">
                            <article className="prose lg:prose-xl">
                                <ReactMarkdown children={props.course.withLanguages[0].description} />
                            </article>
                        </div> */}
          <div className="justify-center text-center mr-2 mt-10">
            <button
              className="inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={() => setClicked(clicked + 1)}
            >
              Start the Course
            </button>
          </div>
        </div>
      )}
      {/* Static sidebar for desktop */}
      <div className="h-full mx-auto lg:flex lg:flex-shrink-0">
        <div className="lg:flex w-60 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="lg:flex w-full min-h-0 lg:flex-1 lg:flex-col ">
            <div className="lg:flex lg:flex-1 w-full lg:flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4 dark:text-white">
                Lessons
              </div>
              <nav className="mt-5 lg:flex-1 col w-full" aria-label="Sidebar">
                {lessons.map((item, index) => (
                  <div className="col w-full" key={index}>
                    <a
                      onClick={() => setClicked(index)}
                      key={index}
                      className={classNames(
                        index === clicked
                          ? " bg-gray-600 text-white"
                          : "text-gray-700 dark:text-slate-200 hover:bg-gray-700 hover:text-white",
                        "mt-2 group w-full flex items-center text-center justify-center px-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      {item.title.substring(0, 30)}
                      {clicked > index - 1 ? (
                        <CheckIcon
                          className="h-5 w-5 w-full text-right text-black dark:text-white"
                          aria-hidden="true"
                        />
                      ) : (
                        <></>
                      )}
                    </a>
                  </div>
                ))}
              </nav>
            </div>

            {finish_notification}
          </div>
        </div>
      </div>
      <EUFooter/> 
    </>
  );
}
