import { collection, doc, setDoc, getDoc, getDocs, query, where, QuerySnapshot } from "firebase/firestore";
import RadarComponent from '../components/RadarComponent';

import { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { db } from '../context/firebase';

import { Fragment } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3CenterLeftIcon,
  BellIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  BanknotesIcon,
  BuildingOfficeIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid'

import { CheckIcon, AcademicCapIcon } from '@heroicons/react/20/solid'
import Suggestions from "../components/Suggeststions";
import EUFooter from "../components/EUFooter";

const timeline = [
  {
    id: 1,
    content: 'Completed Empathy course',
    target: '',
    href: '#',
    date: 'Apr 27',
    datetime: '2023-04-27',
    icon: CheckIcon,
    iconBackground: 'bg-green-500',
  },
  {
    id: 1,
    content: 'Completed Self-Awareness course',
    target: '',
    href: '#',
    date: 'Apr 26',
    datetime: '2023-04-26',
    icon: CheckIcon,
    iconBackground: 'bg-green-500',
  },
]



function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}


const Home = () => {
  const { user } = UserAuth();
  useEffect(() => {
    if (user === null || user === undefined || Object.keys(user).length === 0) {
      return;
    }
    user.saveDBUser();

  }, [])


  return (
    <>

      <main className="flex-1 pb-8 dark:bg-black dark:text-white ">
        {/* Page header */}
        <div className="bg-white dark:bg-slate-800 shadow">
          <div className="sm:px-6  lg:px-8">
            <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
              <div className="min-w-0 flex-1">
                {/* Profile */}
                <div className="flex items-center">
                  <img
                    className="hidden h-16 w-16 rounded-full sm:block"
                    src={user.authObject.photoURL}
                    alt=""
                  />
                  <div>
                    <div className="flex items-center">
                      <img
                        className="h-16 w-16 rounded-full sm:hidden"
                        src={user.authObject.photoURL}
                        alt=""
                      />
                      <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 dark:text-white  sm:truncate sm:leading-9">
                        Welcome, {user.authObject.displayName}
                      </h1>
                    </div>
                    <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">

                      <dt className="sr-only">Account status</dt>
                      <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                        <CheckCircleIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                          aria-hidden="true"
                        />
                        Verified account
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className="mt-8">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="mt-2 w-full grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
                {/* Card */}

                <div className="overflow-hidden rounded-lg bg-white dark:bg-slate-800  shadow">
                  <div className="p-5 ">
                    <div className="flex items-center">

                      <div className="ml-5 w-0 flex-1">
                        <h2 className=" mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 dark:text-white sm:px-6 lg:px-8">
                          Overview
                        </h2>

                        <RadarComponent />
                      </div>
                    </div>
                  </div>
                </div>



                <div className="overflow-hidden rounded-lg bg-white dark:bg-slate-800 h-full shadow">
                  <div className="p-5">
                    <div className="items-center h-full w-full">

                      <div className="ml-5 h-full">
                        <h2 className=" mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 dark:text-white sm:px-6 lg:px-8">
                          Suggestions
                        </h2>

                        <div className="mt-10 h-full p-4" >

                          <Suggestions></Suggestions>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>



          </div>
        </div>

      <EUFooter/> 
      </main>

    </>
  )
}

export default Home
