import { UserAuth } from "../context/AuthContext";
import { Course } from "../utils/CourseClass";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import EUFooter from "../components/EUFooter";

import { Link } from "react-router-dom";

import React, { useRef, useEffect } from "react";

function useHorizontalScroll() {
  const elRef: any = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e: any) => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: "smooth",
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
}

export function CoursesView() {
  let { courses } = UserAuth();
  let { userCourses } = UserAuth();
  let { user } = UserAuth();

  for (let i = 0; i < courses.length; i++) {
    user.isAttendingCourse(courses[i].meta.courseID);
  }

  const filteredCourses = courses.filter((courses: any) => {
    // Check some condition here, e.g., course meets certain criteria
    // and return true if it should be included in the filtered array
    for (let i = 0; i < courses.withLanguages.length; i++) {
      for (let j = 0; j < courses.withLanguages[i].lessons.length; j++) {
        return courses.withLanguages[i].lessons[j].lessonType !== "Audio";
      }
    }
    // Change this condition as needed
  });
  return (
    <>
      <main className="relative h-full w-full pb-8">
        <div className="mx-auto mt-20 justify-center text-center">
          <h1 className="justify-center text-center dark:text-white">
            Your courses
          </h1>

          <h1 className="justify-center mt-10 text-center dark:text-white"></h1>
        </div>


        <div className="grid-cols-2 sm:grid md:grid-cols-4 lg:grid-cols-6">
          {userCourses.map((course: Course) => (
            <div
              key={course.meta.courseID}
              className="flex-1 divide-y mr-3 ml-3 mt-2 mb-2 divide-gray-200 rounded-lg bg-white dark:bg-slate-800 text-center shadow"
            >
              <div className="mt-5 pb-4 pt-1">
                <img
                  className="mx-auto h-56 w-56 object-fit"
                  src={course.meta.previewImage}
                  alt=""
                />
                <h3 className="mt-6 text-sm font-medium text-gray-900 dark:text-slate-300">
                  <Link
                    to={
                      "/dashboard/my-courses/" +
                      "en" +
                      "/" +
                      course.meta.courseID
                    }
                  >
                    {course.withLanguages[0].name}
                  </Link>
                </h3>
                <dl className="mt-1 flex flex-grow flex-col justify-between">
                  <dt className="sr-only">Role</dt>
                  <dd className="mt-3">
                    {course.meta.category !== "" ? (
                      <span className="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                        {course.meta.category}
                      </span>
                    ) : (
                      <></>
                    )}
                  </dd>
                </dl>
              </div>
            </div>
          ))}
        </div>

        <div className="mx-auto mt-20 justify-center text-center">
          <h1 className="justify-center text-center dark:text-white">
            Available courses
          </h1>

          <h1 className="justify-center mt-10 text-center dark:text-white"></h1>
        </div>

        <div className="grid-cols-2 sm:grid md:grid-cols-4 lg:grid-cols-6 ">

          {filteredCourses.map((course: Course) => (
            <div
              key={course.meta.courseID}
              className="flex-1 divide-y mr-3 ml-3 mt-2 mb-2 divide-gray-200 rounded-lg bg-white dark:bg-slate-800 text-center shadow"
            >
              <div className="mt-5 pb-4 pt-1">
                <img
                  className="mx-auto h-56 w-56 object-fit"
                  src={course.meta.previewImage}
                  alt=""
                />
                <h3 className="mt-6 text-sm font-medium text-gray-900 dark:text-slate-300">
                  <Link to={"/dashboard/courses/en/" + course.meta.courseID}>
                    {course.withLanguages[0].name}
                  </Link>
                </h3>
                <dl className="mt-1 flex flex-grow flex-col justify-between">
                  <dt className="sr-only">Role</dt>
                  <dd className="mt-3">
                    {course.meta.category !== "" ? (
                      <span className="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                        {course.meta.category}
                      </span>
                    ) : (
                      <></>
                    )}
                  </dd>
                </dl>
              </div>
            </div>
          ))}
        </div>
      <EUFooter/> 
      </main>
    </>
  );
}
