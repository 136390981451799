

interface Props {
    on_selected: (d: number) => void;
}

export function DificultySelector(props: Props) {
    return (<>
        <h3 className="mt-1 text-2xl leading-6 text-gray-600 dark:text-white">Pick a dificulty for the challenge</h3>
        <ul className="grid w-full gap-6 md:grid-cols-3">

            {["easy", "medium", "hard"].map((d, idx) => {
                return <li key={idx}>
                    <input type="radio" onChange={() => { props.on_selected(idx) }} id={d} name="hosting" value="easy" className="hidden peer" required />
                    <label htmlFor={d} className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                        <div className="block">
                            <div className="w-full text-lg font-semibold">{d}</div>
                        </div>
                        <svg aria-hidden="true" className="w-6 h-6 ml-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </label>
                </li>
            })}


        </ul>
    </>)
}